import React from 'react';
import styled from 'styled-components';
import Layout from '../template/index';
import SEO from '../utils/seo';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import MailchimpForm from '../components/MailchimpForm/index'
import DownloadLink from '../bits/downloadLink';
import enhancedGovernance from '../../src/static/Enhanced Governance.pdf';
//import geckoDeck from '../../src/static/GECKO DECK.pdf';



const Intro = styled.div`
  grid-column: span 5 / 8;
  background: var(--color-background-500);
  padding: 2rem;
  z-index: 1;
  margin-left: 16px;
  @media only screen and (max-width: 768px) {
    padding: 0;
    background: none;
  }
`


const ColumnRow = styled.section`
  padding: var(--var-padding-m) 16px;
  background: white;
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
`
const Column = styled.div`
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;

  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`

const Text = styled.p`
  text-align: justify;
  margin-right: 20px;
`
const Header = styled.h3`
  margin-top: 0px;  
`

export default ({data}) => {

    const download = [
        data.mobileDownload.childImageSharp.fluid,
        {
          ...data.desktopDownload.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]

    const subscribe = [
        data.mobileSubscribe.childImageSharp.fluid,
        {
          ...data.desktopSubscribe.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]

    return(
        <Layout>
            <SEO title="Download" />
            <ColumnRow>
            <Column>
            <Intro>
            <br />
            <br />
            <h4>Gain Market insight</h4>
            <Text>
            Download our latest brochure on how GECKO can streamline your operations, provide a centralised log of 
            all outsourced activities and strengthen your compliance.
            </Text>

            <br />
            <h4>Latest Materials</h4>

            <DownloadLink href={enhancedGovernance} download>
              <b style={{fontSize:"25px"}}>Enhanced Governance, Compliance and Collaboration</b>
              <p style={{color:"#006cff",marginTop:0}}>Experience the true cost reductions as a result of achieving a straight throught process.</p>
            </DownloadLink>

            </Intro>
            </Column>
            <Column>
            <Img fluid={download} alt="gecko download brochure" style={{}}/>
            <br />
            
            </Column>
            </ColumnRow>            
           
            
          <ColumnRow>
              <Column>
              <Img fluid={subscribe} alt="gecko subscribe" style={{}}/>
              </Column>
              <Column>
              <MailchimpForm />
              </Column>
          </ColumnRow>

        </Layout>
    )
}

export const query = graphql`
  query {
    mobileDownload: file(relativePath: { eq: "Download_page.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopDownload: file(
      relativePath: { eq: "Download_page.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mobileSubscribe: file(relativePath: { eq: "subscribe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopSubscribe: file(
        relativePath: { eq: "subscribe.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

  }
`

