import React from 'react';
import styled from 'styled-components';
import addToMailchimp from "gatsby-plugin-mailchimp";
import cp86_file from '../../static/CP86-Overview.pdf';
import gecko_platform_features from "../../static/GECKO_Platform_Features.pdf";
import DownloadLink from '../../bits/downloadLink';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from '@fortawesome/free-solid-svg-icons'



const FormWrapper = styled.div`

  align-items: center;
  justify-content: center;
 
`

const FormButton = styled.button`
  display: block;
  line-height: 24pt;
  padding: 0 20px;
  border: none;
  background: #006cff;
  color: white;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  //border-bottom: 2px solid transparent;
  outline: none;

  &:hover {
    background: #fcfcfc;
    color: #006cff;
  }
`

const Form = styled.form`
  padding: 25px 25px;
`

const Label = styled.label`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 24pt;
`

const Input = styled.input`
  display: block;
  width: 100%;
  line-height: 24pt;
  margin-bottom: 24pt;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #fff;
  padding-left: 5px;

  &:focus {
    border: 1px solid #ff9f00;
  }
`


const EM = styled.em`
  color: #ff9f00;
  font-weight: bold;
`
const Text = styled.p`
  line-height: 24pt;
  font-size: 0.75rem;
  font-style: italic;
`
const Response = styled(Text)`
  font-size: 1rem;
  font-weight:bold;
  color: var(--color-brand-strong);
`

const Header = styled.h3`
  margin: 25px 25px;
`

const Downloader = styled.div`
  display:flex;
  justify-content: centre;
  align-items:centre;
  width: 300px;
`

class MailchimpForm extends React.Component{
    constructor() {
        super()
        this.state = { email: "", fname: "", lname: "", result: null, msg: null, visible:false }
      }
      _handleSubmit = async e => {
        e.preventDefault()
        const result = await addToMailchimp(this.state.email,
          {
            FNAME: this.state.fname,
            LNAME: this.state.lname
          })
        this.setState({result: result})
        this.setState({msg:result.msg})
        this.setState({visible: true})

      }
    handleEmail = event => {
        this.setState({ email: event.target.value })
      }
    handleFname = event => {
        this.setState({ fname: event.target.value })
      }
    handleLname = event => {
        this.setState({ lname: event.target.value })
      }

    render() {

      let responseText = this.state.msg;
      if (responseText !== null)
          {responseText = responseText.split(' <a href')[0]}  
    
    return (
        <FormWrapper>
        <Header>Subscribe and Download</Header>
        <Form onSubmit={this._handleSubmit}>

        <Label for="fname">
          First Name <EM>&#x2a;</EM>
          <Input
            name="fname"
            id="fname"
            placeholder="John"
            required
            type="text"
            onChange={this.handleFname}
          />
        </Label>

        <Label for="lname">
          Last Name <EM>&#x2a;</EM>
          <Input
            name="lname"
            placeholder="Doe"
            required
            type="text"
            onChange={this.handleLname}
          />
        </Label>
  
          <Label for="email">
            Email <EM>&#x2a;</EM>
            <Input
              name="email"
              placeholder="name@example.com"
              required
              type="email"
              onChange={this.handleEmail}
            />
          </Label>
  
          <Text>
            We will send you updates and never spam.
          </Text>
          <Response>
          {responseText}
          </Response>       
          <FormButton type="submit">Subscribe</FormButton>
        </Form>
        <div style={this.state.visible ? { border: "1px solid var(--color-brand-strong)", textAlign: "center", padding: "10px"} : { display: "none" }}>
        <DownloadLink href={gecko_platform_features} download><b>Download File </b>
        <FontAwesomeIcon icon={faDownload} />
        </DownloadLink>
        </div>

      
        </FormWrapper>
    )
}
}


export default MailchimpForm;